import { Container, Grid, IconButton } from '@mui/material'
import React, { Fragment, useEffect, useState } from 'react'
import sogenuvotext from '../../assets/images/sogenuvotext.svg'
import { Facebook, Instagram, PhoneCallback } from '@mui/icons-material'
import { blue, green, red } from '@mui/material/colors'
import axios from 'axios'

const API_URL = 'https://api.sogevo.com/V1.0.0'

// const API_URL = 'http://192.168.1.170:5000/V1.0.0'

export default function AppBar() {
    const [adminSettings, setAdminSettings] = useState(null)

    const fetchSettings = async () => {
        await axios({
            method: "get",
            url: `${API_URL}/settings`,
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then(async function (response) {
                try {
                    if (response.data?.responseCode === '0') {
                        const mAdminSettings = {}
                        response.data.data.forEach(element => {
                            if (element.wording === "phone-number") {
                                mAdminSettings.phoneNumber = element.value
                            } else if (element.wording === "whatsapp") {
                                mAdminSettings.whatsapp = element.value
                            }
                        })

                        setAdminSettings(mAdminSettings)
                    }
                } catch {
                    //
                }
            })
            .catch(function (error) {
                console.log(error)
            })
    }

    useEffect(() => {
        fetchSettings()
    }, [])

    return (
        <Fragment>
            <div className='app-bar'>
                <Container>
                    <Grid container>
                        <Grid item xl={2} lg={3} md={3} sm={4} xs={6}>
                            <img src={sogenuvotext} alt="SOGENUVO Logo" width={`200`} style={{ marginTop: -15 }} />
                        </Grid>
                        <Grid item xl={10} lg={9} md={9} sm={8} xs={6} textAlign={`right`}>
                            <br />
                            <div></div>
                            <IconButton sx={{ bgcolor: blue[900], color: 'white' }}>
                                <Facebook />
                            </IconButton>
                            &nbsp;&nbsp;
                            <IconButton sx={{ bgcolor: red[700], color: 'white' }}>
                                <Instagram />
                            </IconButton>
                            &nbsp;&nbsp;
                            <IconButton sx={{ bgcolor: green[700], color: 'white' }} onClick={() => {
                                window.location = `tel:${adminSettings ? adminSettings.phoneNumber : '22891019245'}`
                            }}>
                                <PhoneCallback />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        </Fragment>
    )
}