import { Route, Routes } from 'react-router-dom'
import './App.css'
import Home from './Components/Home'
import Car from './Components/Car'

function App() {
    return (
        <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/car/:id' element={<Car />} />
        </Routes>
    )
}

export default App
